<template>
  <Modal class="news-options mobile-s" ref="modal" :settings="{ opened: true, outClose: true }" @close="modalClose" @open="modalOpen">
    <div class="news-options-logo" slot="header">
      <p class="news-options-icon">
        <iconic name="myspace" />
      </p>
    </div>
    <div class="news-options-content">
      <ListItem text="Editar" icon="pencil_square" class="news-options-item" @click="editNews" />
      <ListItem text="Borrar" icon="trash_solid" class="news-options-item" @click="deleteNews" />
      <ListItem :text="`${isNewsArchive(news) ? 'Visible' : 'Archivada'}`" :icon="`${isNewsArchive(news) ? 'eye' : 'eye_slash'}`" :class="`news-options-item`" @click="toggleVisiblitity" />
    </div>
  </Modal>
</template>

<script>
  import NewsMixing from "./NewsMixing";
  export default {
    mixins: [NewsMixing],
    data: function() {
      return {
        animation: null,
        duration: 250,
        eventX: 0,
        eventY: 0,
      };
    },
    components: {},
    computed: {
      news: function() {
        return this.$store.state.news.newsMenu;
      },
    },
    methods: {
      modalClose: function() {
        this.$store.state.news.newsMenu = null;
      },
      closeNewsVisor: function({ removeQuery }) {
        this.$store.state.news.NewsVisor = null;
        if (removeQuery) {
          this.$router.push({ query: { news: undefined } }).catch(() => {});
        }
      },
      editNews: function() {
        this.$router.push({ path: "formNews", query: { new: this.news._id } }).catch(() => {});
        this.modalClose();
        this.closeNewsVisor({ removeQuery: false });
      },
      deleteNews: function() {
        this.deleteNew(this.news);
        this.modalClose();
        this.closeNewsVisor({ removeQuery: true });
      },
      toggleVisiblitity: function() {
        const newStatus = this.isNewsArchive(this.news) ? false : true;
        this.onToggle(newStatus, this.news._id);
        this.modalClose();
        this.closeNewsVisor({ removeQuery: true });
      },
      modalOpen: async function() {
        await this.sleep(100);
        const modalCard = this.$refs.modal.$el.querySelector(".modal-card");
        if (modalCard) {
          const cardWidth = modalCard.offsetWidth;
          const cardHeight = modalCard.offsetHeight;
          modalCard.style.left = `${this.eventX - cardWidth}px`;
          modalCard.style.opacity = 1;

          const windowsHeight = window.innerHeight || window.innerHeight;
          const totalTop = this.eventY + cardHeight;

          if (totalTop > windowsHeight) {
            modalCard.style.top = `${this.eventY - cardHeight}px`;
          } else {
            modalCard.style.top = `${this.eventY}px`;
          }
        }
      },
    },
    mounted: function() {
      this.eventX = this.news.event.clientX;
      this.eventY = this.news.event.clientY;
    },
  };
</script>

<style lang="scss">
  .news-options {
    cursor: inherit;
    background: rgba(0, 0, 0, 0.3);
    &-logo {
      color: $primary-color;
      font-weight: bold;
    }
    .modal-card {
      position: fixed;
      overflow: hidden;
      opacity: 0;
      @include Transition(0.25s);
      @include Animation(slide-bottom, 0.25s);
      box-shadow: 0 0 $mpadding * 1 rgba(0, 0, 0, 0.616);
      &-content {
        padding: 0 0 0 0 !important;
      }
      &-header-right {
        .button {
          background-color: $primary-color;
          color: #fff !important;
          border-radius: 0 0 0 $mpadding !important;
        }
        .button:hover {
          background-color: $secondary-color;
          color: #fff !important;
        }
      }
    }
    &-icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 90px;
      margin: 0 20px;
    }
    &-item {
      border-top: solid 1px $alto;
      padding: $mpadding/1.5 $mpadding !important;
      .iconic {
        font-size: 75%;
      }
    }
  }
</style>
